import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class Service574Page extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="574"
          description="View route information and buy your ticket for the 574 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">574 service</h1>
              <p>Pinfold Lane Low Common - Rodillian Academy</p>
              <p>Rodillian Academy - Pinfold Lane Low Common</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="574 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1wrGbz-iLH-PmlTtWI9fBI40SzZlJOpQ&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Rodillian Academy
                </p>
                <p>
                From Church Lane, Leeds Road, Styebank Lane, Park Lane, Park West, Ingram Parade, Church Street, Butcher Lane, Marsh Street, Carlton Lane, Leadwell Lane, Rodillian Academy.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="574 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1r3r7Hd9kFvJooiWRa2gN-NQsO7L63e4&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Rodillian Academy
                </p>
                <p>
                  From Rodillian Academy, Thorpe Lower Lane, Leeds Road, WoodLane, Ingram Parade, Park West, Park Lane, Styebank Lane, Leeds Road, Methley Lane, Leeds Road, Churchside, Leeds Road, Barnsdale Road.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Pinfold Lane Low Common</td>
                      <td>DEP</td>
                      <td>0709</td>
                    </tr>
                    <tr>
                      <td>Oulton Church</td>
                      <td>DEP</td>
                      <td>0715</td>
                    </tr>
                    <tr>
                      <td>Ingham Parade near Church Street</td>
                      <td>DEP</td>
                      <td>0726</td>
                    </tr>
                    <tr>
                      <td>Rodillian Academy</td>
                      <td>ARR</td>
                      <td>0732</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Rodillian Academy</td>
                      <td>DEP</td>
                      <td>1440</td>
                    </tr>
                    <tr>
                      <td>Ingham Parade near Church Street</td>
                      <td>DEP</td>
                      <td>1454</td>
                    </tr>
                    <tr>
                      <td>Oulton Church</td>
                      <td>DEP</td>
                      <td>1502</td>
                    </tr>
                    <tr>
                      <td>Pinfold Lane Low Common</td>
                      <td>ARR</td>
                      <td>1507</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default Service574Page
